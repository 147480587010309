import { Navigate } from 'react-router-dom';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useState } from 'react';
import { Typography } from 'antd';

import { JourneyModalMain } from 'src/components/Journey/ModalLayout';
import { TextBlock, ModalConfirmation } from 'src/components/Misc';
import { BackupSummaryCard } from 'src/components/Backup/Summary';
import { useBackup } from 'src/context/backup';
import { BackupStatusEnum } from 'src/graphql/schema';
import env from 'env';

import BackupSummaryActionsContainer from './BackupSummaryActionsContainer';
import BackupResultItemContainer from './BackupResultItemContainer';

dayjs.extend(utc);

const BackupSummaryContainer = () => {
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const { backup, currentUserRole } = useBackup();

  const currentDateToUtc = dayjs.utc(new Date());
  const nextBackupDate = currentDateToUtc.get('hour') < 6 ? currentDateToUtc : dayjs(currentDateToUtc).add(1, 'days');

  if (backup?.status === BackupStatusEnum.Draft) return <Navigate to={`/backups/${backup.id}/platform`} />;

  const showConfirmationModal = () => setIsConfirmationOpen(true);

  const closeConfirmationModal = () => setIsConfirmationOpen(false);

  return (
    <JourneyModalMain className="ck-backup__main">
      <TextBlock
        title="Backup Summary"
        as="h2"
        text="Review your backup configurations, and download your secure backups at any time."
        className="mb-32"
      />

      {backup?.status === BackupStatusEnum.Removed ? (
        <BackupSummaryCard heading="This backup is removed." />
      ) : (
        <BackupSummaryCard
          subHeading={backup.status === BackupStatusEnum.Active ? 'Next backup scheduled' : ''}
          heading={
            backup.status === BackupStatusEnum.Active
              ? dayjs(nextBackupDate).format('dddd, D MMMM YYYY')
              : 'No backup scheduled'
          }
          action={<BackupSummaryActionsContainer />}
        />
      )}

      {backup.backupAssets.length ? (
        backup.backupAssets.map((item) => (
          <BackupResultItemContainer
            backupStatus={backup.status}
            key={item.id}
            data={item}
            showConfirmationModal={showConfirmationModal}
          />
        ))
      ) : (
        <Typography.Title level={3} type="secondary">
          No versions available.
        </Typography.Title>
      )}

      <ModalConfirmation
        isOpen={isConfirmationOpen}
        title="Download Link Sent"
        text={`We've emailed you a link to download the requested material. Please check your inbox to continue. If you need further assistance, contact our support team at <a href="mailto: ${env.CODEKEEPER_SERVICE_EMAIL}">${env.CODEKEEPER_SERVICE_EMAIL}</a>.`}
        onClose={closeConfirmationModal}
      />
    </JourneyModalMain>
  );
};

export default BackupSummaryContainer;
